<style scoped>
/* .detect-info {
  margin-top: 200px;
} */
.detect-info >>> .el-card__header {
  border: none;
}
.detect-info .el-card__header .icon-fanhui-:before {
  margin-right: 10px;
}
.detect-info .back-btn {
  color: #333;
  margin-left: 65px;
  position: absolute;
  left: 0;
  top: 50px;
}
.card-title {
  font-size: 30px;
  /* line-height: 80px; */
  color: #333333;
  margin-top: 60px;
}
.card-content {
  width: 1116px;
  height: 350px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: space-around;
}
.detect-info .content {
  padding: 0 65px;
  box-sizing: border-box;
}
.detect-info .content .search-box {
  display: flex;
  justify-content: space-between;
}
.detect-info .content .content-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 0;
}
.detect-info .content .content-box .content-title {
  font-size: 24px;
  color: #333333;
  font-weight: normal;
  margin-bottom: 30px;
}
.detect-info .content .content-box .item-box {
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  /* align-items: center; */
}
.detect-info .content .content-box .item-box .item {
  margin: 0 20px 0;
  cursor: pointer;
}
</style>
<template>
  <div class="detect-info" id="container">
    <el-card>
      <div slot="header">
        <el-button
          class="back-btn iconfont icon-fanhui-"
          type="text"
          @click="backHome"
        >
          返回
        </el-button>
        <p class="card-title">操作员：{{ operation_name }}</p>
      </div>
      <div class="content">
        <div class="search-box">
          <date-picker
            @dateChange="dateChange"
            @typeChange="typeChange"
            :type="time_type"
            :dateValue="from_time"
          ></date-picker>
        </div>
        <div class="content-box">
          <el-select
            v-model="patient_project_name"
            placeholder="请选择"
            @change="selectChange"
          >
            <el-option
              v-for="item in titleList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <!-- <h6 class="content-title">{{ operation.patient_project_name }}</h6> -->
          <el-empty v-if="isShow" :image="emptyImg" :image-size="350">
            <template slot="description">
              <span style="color: #999"> 没有查询到相应内容 </span>
            </template>
          </el-empty>
          <el-row
            class="item-box"
            v-else
            type="flex"
            justify="center"
            :gutter="20"
          >
            <el-col
              :span="12"
              class="item"
              v-for="item in list"
              :key="item.id"
              @click.native="toDetectLine(item.patient_project_item)"
            >
              <info-item :list="item"></info-item>
              <div class="btn-box">
                <el-button
                  icon="el-icon-download"
                  type="primary"
                  plain
                  @click.stop="download(item.patient_project_item)"
                  >下载结果</el-button
                >
              </div>
            </el-col>
          </el-row>
          <div class="card-bottom">
            <el-pagination
              class="pagination"
              background
              :page-size="2"
              layout="prev, pager, next"
              :total="total"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import InfoItem from "@/components/InfoItem/InfoItem.vue";
import DatePicker from "@/components/DatePicker/DatePicker.vue";
import { ownerProjectItemTotal, ownerStatExport } from "@/api/base";
export default {
  name: "detectInfo",
  data() {
    return {
      emptyImg: require("@/assets/images/empty.png"),
      patient_project_name: "HID",
      time_type: null,
      from_time: null,
      to_time: null,
      dataList: [], //原始数据
      newData: null, //下拉筛选后的数据
      list: null, //分页后展示数据
      total: null,
      current: null,
      isShow: false,
      operation_name: null,
      device_id: null,
      operation_phone: null,
      titleList: [
        {
          value: "HID",
          label: "人类传染性疾病（HID）",
        },
        {
          value: "PG",
          label: "药物基因组（PG）",
        },
        {
          value: "AID",
          label: "动物感染疾病（AID）",
        },
      ], //展示的下拉标题
    };
  },
  components: {
    DatePicker,
    InfoItem,
  },
  created() {
    //初始化数据 title time_type from_time to_time
    this.device_id = this.$route.query.deviceId;
    this.operation_name = this.$route.query.operation_name;
    this.operation_phone = this.$route.query.operation_phone;
    this.time_type = this.$route.query.time_type;
    this.from_time = this.$route.query.from_time;
    this.to_time = this.$route.query.to_time;
    this.getList();
  },
  methods: {
    //获取列表信息
    async getList(page = 1) {
      this.current = page;
      let {
        time_type,
        from_time,
        to_time,
        current,
        operation_phone,
        device_id,
      } = this;
      let res = await ownerProjectItemTotal({
        time_type,
        from_time,
        to_time,
        operation_phone,
        current,
        device_id,
      });
      if (res.code == 1 && res.data) {
          //如果有值
        this.dataList = res.data.records;
        this.newData = this.dataList.filter(function (item) {
          return item.patient_project_name == "HID";
        });
        if (this.newData.length == 0) {
          this.isShow = true;
        } else {
          this.isShow = false;
          this.total = this.newData[0].item.length;
          this.list = this.pagination(this.newData[0].item, page);
        }
      }else{
          this.isShow=true
          this.dataList = []
      }
    },
    //下拉框值改变
    selectChange(e) {
      this.patient_project_name = e;
      this.newData = this.dataList.filter(function (item) {
        return item.patient_project_name == e;
      });
      if (this.newData[0]) {
        this.list = this.pagination(this.newData[0].item, 1);
        this.total = this.newData[0].item.length;
        this.isShow = false;
      } else {
        this.list = null;
        this.total = "0";
        this.isShow = true;
      }
    },
    //前端分页  list是需要分页的数据  n是传入第几页 从第一页开始  根据传入的页码 返回需要展示的数组
    handleCurrentChange(page) {
      let data = this.newData[0].item;
      this.list = this.pagination(data, page);
    },
    //2为每页的size 也可以改为动态传参
    pagination(list, n) {
      let start = 2 * (n - 1);
      let end = 2 * (n - 1) + 2;
      return list.slice(start, end);
    },
    //返回
    backHome() {
      this.$router.back();
    },
    //跳转detect统计页面
    toDetectLine(data) {
      this.$router.push({
        path: "/equipmentLine",
        query: {
          device_id: this.device_id,
          time_type: this.time_type,
          from_time: this.from_time,
          to_time: this.to_time,
          patient_project_name: this.patient_project_name,
          operation_phone: this.operation_phone,
          patient_project_item: data,
        },
      });
    },
    //时间类型选择
    typeChange(e) {
      this.time_type = e;
    },
    //时间选择器返回值 e.dateValue  e.selectValue
    dateChange(e) {
      if (!this.time_type) {
        this.time_type = "3";
      }
      this.from_time = e.from_time;
      this.to_time = e.to_time;
      this.getList();
    },
    //下载
    async download(data) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let {
        patient_project_name,
        from_time,
        device_id,
        to_time,
        time_type,
        operation_phone,
      } = this;
      const res = await ownerStatExport({
        patient_project_item: data,
        patient_project_name,
        device_id,
        from_time,
        to_time,
        time_type,
        operation_phone,
        flag: 1,
      });
      if (res) {
        const blob = new Blob([res]);
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", `${data}.xlsx`);
        link.click();
        link = null;
      }
      loading.close();
    },
  },
};
</script>

<style>
</style>